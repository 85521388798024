<template>
  <div class="container-fluid">
    <b-overlay :opacity="0.92" :show="show" rounded="sm">
      <b-row>
        <b-col>
          <b-jumbotron class="mt-4 h-400">
            <template #header>Transfer balanță (discount)</template>
            <p>Balanța contului Escrow: <b-badge>0 RON</b-badge></p>
            <hr class="my-4">
            <p>
                Calcul:
            </p>
            <b-table head-variant="dark" striped hover :items="items"></b-table>
            <b-row>
              <b-col>
                <b-input-group size="md" prepend="Suma spre plată" append="RON">
                  <b-form-input class="bg-light" readonly :value="(driversAmount + commission).toFixed(2)"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col>
                <b-button
                  :disabled="selected.length === 0"
                  @click="onTransfer"
                  class="w-100"
                  variant="success"
                  to="#"
                >
                    Transferă suma
                    <b-icon icon="arrow-right-circle"></b-icon>
                </b-button>
              </b-col>
            </b-row>
          </b-jumbotron>
        </b-col>
        <b-col>
          <v-multiselect-listbox
            class="mt-4 h-400 custom-multi"
            :options="data"
            v-model="selected"
            :reduce-display-property="formatLabel"
            :reduce-value-property="(item) => item.driverId"
            search-options-placeholder="Caută șofer"
            selected-options-placeholder="Caută șofer adăugat">
          </v-multiselect-listbox>
        </b-col>
      </b-row>
      <template #overlay>
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
        <h5 class="mt-3">Transfer balanță către șoferi. Te rugăm să aștepți...</h5>
      </div>
    </template>
    </b-overlay>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'TransferDiscount',
  mounted () {
    this.getIsInProcess()
    this.getData()
    this.interval = setInterval(() => {
      this.getIsInProcess()
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  data () {
    return {
      show: false,
      data: [],
      selected: [],
      interval: null
    }
  },
  computed: {
    apiUrl () {
      return config.baseApiUrl + '/api/driver-invoices/unpaid-discount'
    },
    selectedDrivers () {
      return this.data.filter((item) => {
        return this.selected.includes(item.driverId)
      })
    },
    commission () {
      return 0
    },
    driversAmount () {
      let driversAmount = 0
      this.selectedDrivers.forEach((item) => {
        driversAmount += item.amount
      })
      return parseFloat(driversAmount.toFixed(2))
    },
    items () {
      return [
        {
          Numar_soferi: this.selectedDrivers.length,
          Comisioane: this.commission.toFixed(2) + ' RON',
          Suma_catre_soferi: this.driversAmount.toFixed(2) + ' RON'
        }
      ]
    }
  },
  methods: {
    initializeTransfer () {
      this.$axios.post(config.baseApiUrl + '/api/driver-invoices/process-discount', {
        userIds: this.selected
      })
        .then(() => {
          this.selected = []
          this.data = []
        })
    },
    formatLabel (driver) {
      return driver.name + ' | ' + driver.indicativ + ' | ' + driver.amount + ' RON'
    },
    getData () {
      this.$axios.get(this.apiUrl)
        .then((response) => {
          this.data = response.data
        })
    },
    getIsInProcess () {
      this.$axios.get(config.baseApiUrl + '/api/driver-invoices/is-in-process-discount')
        .then((response) => {
          if (this.show && !response.data.isInProgress) {
            window.location.reload()
          }
          this.show = response.data.isInProgress
        })
    },
    onTransfer () {
      this.show = true
      this.$axios.post(config.baseApiUrl + '/api/driver-invoices/process-discount', {
        userIds: this.selectedDrivers.map((item) => {
          return item.driverId
        })
      })
        .catch(() => {
          this.show = false
        })
    }
  }
}
</script>

<style scoped>
  .table tr th,
  .table tr td {
    vertical-align: middle;
  }
</style>
